import React from "react"

import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import { DefinitionList, DefinitionTerm, DefinitionDescription, TextLinkAnchor } from "../components/Trypography"

const LinksPage = () => {
  return (
    <Layout pageTitle="Links">
      <PageTitle title="Links" />

      <DefinitionList>
        <DefinitionTerm>
          GitHub
        </DefinitionTerm>
        <DefinitionDescription>
          <TextLinkAnchor href="https://github.com/yusukefs" target="blank">yusukefs</TextLinkAnchor>
        </DefinitionDescription>

        <DefinitionTerm>
          LinkedIn
        </DefinitionTerm>
        <DefinitionDescription>
          <TextLinkAnchor href="https://www.linkedin.com/in/yusuke-fred-sakai/" target="blank">
            https://www.linkedin.com/in/yusuke-fred-sakai/
          </TextLinkAnchor>
        </DefinitionDescription>
      </DefinitionList>
    </Layout>
  )
}

export default LinksPage
